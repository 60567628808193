<template>
  <div id="transferFunds">
    <div class="content_box">
      <div class="inner_max">
        <h3>{{ $t('menu.transferBetweenAccs') }}</h3>
        <el-row class="form_box d-flex flex-wrap align-content-center" v-if="flag">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-card class="me-0 me-lg-5 mb-5 mb-lg-0">
              <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm">
                <div class="info" v-if="false">
                  We are undergoing system maintenance between 00:30-02:30 (GMT+3) , 8 May. During this time all
                  transfers between accounts will be processed manually, and might be subject to slight delays. Please
                  do not submit transfer requests multiple times. We apologise for any inconvenience caused.
                </div>
                <ul class="mb-3">
                  <li>
                    <span class="special_title mb-2">{{ $t('common.key.from') }}</span>
                    <el-form-item :label="$t('common.field.accNum')" prop="fromAccount">
                      <el-select v-model="selected.fromAccount" data-testid="fromAccount">
                        <el-option
                          v-for="item in formAccountOptions"
                          :key="item.value"
                          :value="item.login + ' - ' + item.currency"
                          :label="getCurrencySymbol(item)"
                          :data-testid="item.login"
                          :disabled="item.balance < 0"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                  <li>
                    <span class="special_title mb-2">{{ $t('common.key.to') }}</span>
                    <el-form-item :label="$t('common.field.accNumTo')" prop="toAccount">
                      <el-select v-model="ruleForm.toAccount" data-testid="toAccount">
                        <el-option
                          v-for="item in toAccountOptions"
                          :key="item.value"
                          :value="item.login"
                          :label="getCurrencySymbol(item)"
                          :data-testid="item.login"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="mb-3">
                  <li>
                    <el-form-item :label="$t('common.field.amt')" prop="amount">
                      <numeric-input
                        class="transfer-amount"
                        v-model="ruleForm.amount"
                        :currency="selected.currentCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <el-button class="bg-primary" @click="submitForm('ruleForm')" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="10">
            <div class="info_box text-primary d-flex flex-wrap align-content-center">
              <div class="p-3 border border-primary rounded">
                <span>{{ $t('transferFunds.pleaseNote') }}</span>
                <br /><br />
                <p class="pb-3">{{ $t('transferFunds.warn1') }}</p>
                <i18n path="transferFunds.warn2" tag="p" class="pb-3">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
                <p>{{ $t('transferFunds.warn3') }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
        <Result v-else>{{ $t('transferFunds.suc') }}</Result>
        <vDialog :show.sync="dialog.show">
          <div class="text-center">
            <i class="icon el-icon-warning-outline text-primary mb-5"></i>
            <div>{{ dialog.text }}</div>
            <template v-if="dialog.type === 'BLACKLISTED'">
              <i18n path="transfer.blackListWarn" tag="p">
                <template v-slot:mailTo>
                  <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL" class="text-primary">{{ GLOBAL_CONTACT_EMAIL }}</a>
                </template>
              </i18n>
            </template>
            <el-button class="mt-3 mt-md-5" @click="dialog.show = false" data-testid="confirm">
              {{ $t('common.button.confirm') }}
            </el-button>
          </div>
        </vDialog>
        <vDialog :show.sync="positionDialog.show">
          <div class="text-center">
            <div class="mb-3 mb-md-5">{{ positionDialog.text }}</div>
            <el-button v-if="positionDialog.showConfirm" @click="equityConfirm(applyTransfer)" data-testid="confirm">
              {{ $t('common.button.confirm') }}
            </el-button>
            <el-button plain v-if="positionDialog.showCancel" @click="hidePositionDialog" data-testid="cancel">
              {{ $t('common.button.cancel') }}
            </el-button>
          </div>
        </vDialog>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import { apiGetTransferData_cp, apiApplyTransfer_cp, apiTransferPermission } from '@/resource';
import splitThousands from '@/util/splitThousands';
import vDialog from '@/components/vDialog';
import Result from '@/components/Result';
import checkPositionMixin from '@/mixins/page/tools/checkPosition';
import blackList from '@/mixins/blackList';
import mixin from '@/mixins';

export default {
  name: 'TransferFunds',
  components: { NumericInput, vDialog, Result },
  mixins: [checkPositionMixin, blackList, mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < 0) {
        callback(new Error(this.$t('common.formValidation.amt0')));
      } else {
        callback();
      }
    };
    return {
      flag: true,
      dialog: {
        show: false,
        text: '',
        type: ''
      },
      formAccountOptions: [],
      toAccountOptions: [],
      selected: {
        fromAccount: '',
        currentCurrency: ''
      },
      ruleForm: {
        amount: '',
        fromAccount: '',
        toAccount: ''
      },
      rules: {
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        fromAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change'
          }
        ],
        toAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    formAccountOptions(options) {
      // 預設第一筆
      if (options.length > 0) {
        const firstAccountIndex = options.findIndex(account => Number(account.balance) >= 0);
        if (firstAccountIndex !== -1) {
          this.selected.fromAccount = 
            this.formAccountOptions[firstAccountIndex].login +
            ' - ' +
            this.formAccountOptions[firstAccountIndex].currency;
        }
      }
    },
    'selected.fromAccount'(value) {
      this.ruleForm.toAccount = '';
      [this.ruleForm.fromAccount, this.selected.currentCurrency] = [...value.split(' - ')];
      this.getToAccounts();
    }
  },
  methods: {
    getToAccounts() {
      this.toAccountOptions = this.formAccountOptions.filter(t => {
        return t.currency == this.selected.currentCurrency && t.login != this.ruleForm.fromAccount;
      });
    },
    getCurrencySymbol(item) {
      return (
        item.login +
        ' - ' +
        this.$options.filters.currencyToSymbol(item.currency) +
        splitThousands(item.balance, 2) +
        ' ' +
        item.currency
      );
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.checkPrerequisiteAndSubmit();
        else return false;
      });
    },
    checkPrerequisiteAndSubmit() {
      if (!this.checkTransferBlacklist()) {
        this.transferErrMsg();
      } else {
        apiTransferPermission({ account: this.ruleForm.fromAccount })
          .then(result => {
            if (result.data.data === 'BLACKLISTED') {
              this.dialog = {
                show: true,
                text: '',
                type: 'BLACKLISTED'
              };
            } else if (result.data.data === 'CREDITEXIST') {
              this.dialog = {
                show: true,
                text: this.$t('transfer.creditWarn'),
                type: 'CREDITEXIST'
              };
            } else if (result.data.data === 'PERMITTED') {
              this.checkPosition(this.ruleForm.fromAccount, this.ruleForm.amount, 'transferFunds', this.applyTransfer);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    async checkTransferBlacklist() {
      if (this.$route.params.passBlacklist) return true;
      else
        await this.getTransferBlacklist().then(resp => {
          return resp;
        });
    },
    applyTransfer() {
      apiApplyTransfer_cp({ ...this.ruleForm }, this.token)
        .then(result => {
          if (result.data.code === 0) this.flag = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchTradingAccount() {
      apiGetTransferData_cp()
        .then(resp => {
          if (resp.data.code === 0) this.formAccountOptions = resp.data.data.logins;
        })
        .catch(err => {
          this.$message({ message: 'Fetch trading accounts failed.', type: 'error' });
        });
    }
  },
  mounted() {
    this.fetchTradingAccount();
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/transferFunds.scss';
</style>
