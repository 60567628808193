import { render, staticRenderFns } from "./TransferFunds.vue?vue&type=template&id=e2f040fe&scoped=true"
import script from "./TransferFunds.vue?vue&type=script&lang=js"
export * from "./TransferFunds.vue?vue&type=script&lang=js"
import style0 from "./TransferFunds.vue?vue&type=style&index=0&id=e2f040fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2f040fe",
  null
  
)

export default component.exports